import {ref} from 'vue'
import type {Ref, UnwrapRef} from 'vue';
import axios from "axios";
import type {MessageInPageProps} from "@/Types/AppTypes.js";

export async function useApi<T>(url: string, method: 'get' | 'post' | 'delete' | 'put', data?: any): Promise<{
    responseData: Ref<(UnwrapRef<T> | null)>;
    messageResult: Ref<UnwrapRef<MessageInPageProps>>
}> {
    const responseData = ref<T | null>(null)

    let response = null;
    const messageResult = ref<MessageInPageProps>({closable: true, display: false, message: '', severity: undefined});
    try {
        switch (method) {
            case 'get':
                response = await axios.get(url);
                responseData.value = response.data;
                break;
            case 'post':
                response = await axios.post(url, data);
                responseData.value = response.data;
                break;
            case 'put':
                response = await axios.put(url, data);
                responseData.value = response.data;
                break;
            case 'delete':
                response = await axios.delete(url);
                responseData.value = response.data;
                break;
        }
        if (response) {
            if (response.data.status === 'success') {
                messageResult.value = {
                    display : true,
                    message : response.data.message,
                    severity: "success",
                    closable: true
                };
            } else {
                messageResult.value = {
                    display : true,
                    message : response?.data?.message ?? 'Unknown error! Please check the action was successful!',
                    severity: 'error',
                    closable: true
                }
            }
        }
    } catch (e: any) {
        responseData.value = e.response?.data ?? null;
        messageResult.value = {
            display : true,
            message : e.response?.data.message || e.message,
            severity: 'error',
            closable: true
        }
    }


    return {responseData, messageResult}
}
